import styled from 'styled-components';
import {ColorData} from '../data/colorData';

const Section = styled.section`
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 12px;
    background-color: ${ColorData.background1};
    color: ${ColorData.textColor};
    text-shadow: 3px 3px 3px #000000;

    @media screen and (max-width: 768px) {
        height: 100%;
    }
`;

const Container = styled.div`
    @media screen and (max-width: 768px) {
        padding-top: 2rem;
        padding-bottom: 0;
    }
`;

const TextSection = styled.div`
    font-size: 60px;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.1;
    padding: 20rem 4rem;
    width: 70%;

    @media screen and (max-width: 768px) {
        padding: 10rem 2rem;
        width: 100%;
        font-size: 35px;
    }
`;

const Image = styled.div`
    margin-right: 4rem;
    margin-left: 4rem;
    padding-bottom: 14rem;

    @media screen and (max-width: 768px) {
        margin-right: 2rem;
        margin-left: 2rem;
        padding-bottom: 8rem;
    }

    img {
        width: 100%;
        height: 800px;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 100%;
            height: 300px;
        }

        ${image => { if(image === null) return `
            display: none;
        `}}
    }
`;
interface infoTypes {
    paragraphOne: string; 
    myRef: any;
    image: any;
}

const Hero = ({paragraphOne, myRef, image}: infoTypes) => {
    return (
        <Section>
            <Container id={myRef}>            
                <TextSection>
                    <p>{paragraphOne}</p>
                </TextSection>
                <Image>
                    <img src={image} alt=""/>
                </Image>
            </Container>
        </Section>
    )
}

export default Hero;
