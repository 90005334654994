import HeroImage from '../images/HeroImage.jpg';

export const HeroData = {
    heading: 'About',
    paragraphOne: 'Apthletic is a technology startup building next generation wagering entertainment.',
    image: HeroImage,
};

export const InfoDataOne = {
    heading: 'About',
    paragraphOne: 'Located in Melbourne, Australia, Apthletic is a technology startup building next generation entertainment for the wagering industry. We use prioritory in-house technology to power exciting customer experiences through various insights, rewards and responsible online gambling practices.',
    paragraphTwo: '',
    reverse: true,
    link: "",
};

export const InfoDataTwo = {
    heading: 'Our Vision',
    paragraphOne: 'Excite and delight our users through new innovation. We want to build the worlds largest peer-to-peer betting network of users.',
    paragraphTwo: '',
    reverse: false,
    link: "",
};

export const InfoDataThree = {
    heading: 'RivalBet',
    paragraphOne: 'RivalBet is our first initiative, unlike anything else, and is a first of its kind. RivalBet is a fun, rewarding, and unique betting platform that allows you to bet not only your friends but also anyone on the platform.',
    paragraphTwo: 'Bet, banter and brag against your mates. Welcome to your new world of betting.',
    reverse: true,
    link: "RivalBet",
};
