import styled, { css } from 'styled-components';
import BarlowCondensed from '../fonts/BarlowCondensed.ttf';
import BarlowMedium from '../fonts/BarlowCondensed-Medium.ttf';
import HeroImage from "../images/rb/hero.webp";
import ImageCarousel from '../components/ImageCarousel';
import CarouselImage1 from "../images/rb/chat-feature.webp"
import CarouselImage2 from "../images/rb/featured-bets.webp"
import CarouselImage3 from "../images/rb/groups-feature.webp"
import CarouselImage4 from "../images/rb/live-bet-feature.webp"
import CarouselImage5 from "../images/rb/simple-easy.webp"
import CarouselImage6 from "../images/rb/social.webp"
import BottomImage from "../images/rb/screenshot-download.webp"


const FontFace = css`
  @font-face {
    font-family: 'BarlowMedium';
    src: local('Barlow'), url(${BarlowMedium}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Barlowd';
    src: local('Barlow'), url(${BarlowCondensed}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const SectionContainer = styled.div<{isWhite?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 950px;
    background-color: ${({isWhite}) => (isWhite ? "white" : "#011752")};
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  height: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px
  }
`

const TextImageColumn = styled.div`
  width: 40%;
  maxWidth: 780px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @media (max-width: 800px) {
    width: 80%;
  }
`

const Spacer = styled.div`
  width: 10%;

  @media (max-width: 800px) {
    display: none;
  }
`;

const Heading = styled.h1`
  ${FontFace}
  font-family: 'BarlowMedium', sans-serif;
  font-size: 60px;
  color: white;
  text-align: left;
`;

const Text = styled.p`
  ${FontFace}
  font-family: 'Barlowd', sans-serif;
  font-size: 22px;
  color: white;
  text-align: left;
`;

const ImageContainer = styled.div`
width: 40%;
margin-left: 10px;

@media (max-width: 800px) {
  width: 80%;
  margin-left: 0px;
  margin-right: 40px;
}
`

const ImageContainerOne = styled(ImageContainer)`
  img {
    width: 520px;
    height: 640px;
  }

  @media (max-width: 800px) {
    img {
        width: 440px;
        height: 540px;
    }
  }
`

const ImageContainerTwo = styled(ImageContainer)`
  img {
    width: 520px;
    height: 500px;
  }

  @media (max-width: 800px) {
    margin-top: 60px;
    img {
        width: 420px;
        height: 400px;
    }
  }
`

const Rb = () => {    

    const carouselData = [
        {   
            image: CarouselImage1, 
            heading: "Chat", 
            text: "When you bet with mates, you want to be able to chat with them. Rivalbet lets you chat with friends about your bets, scores and results. Its more fun to bet with friends when you can banter with them!"
        }, 
        {
            image: CarouselImage2,
            heading: "Featured Bets",
            text: "Introducing Multi Madness, a betting tournament with massive payouts and Bet of the Day, a bet with added cash from RivalBet.",
        }, 
        {
            image: CarouselImage3,
            heading: "Groups",
            text: "RivalBet offers a variety of sports betting groups such as afl, nrl, nba, nfl, ufc, epl, champions league, horse racing and the Olympics.",
        }, 
        {
            image: CarouselImage4,
            heading: "Live Bets",
            text: "When your bet is live you can get up to date scores and results. You can see how many friends are left in the wager when you bet with friends.",
        }, 
        {
            image: CarouselImage5,
            heading: "Simple and Easy",
            text: "RivalBet is simple to navigate and easy to use. Create bets, share bets, join bets and add Rivals with ease. Punting with mates has never been easier.",
        }, 
        {
            image: CarouselImage6,
            heading: "Social",
            text: "Bet against your mates and view their bets. Join large public groups and create exclusive private groups.",
        }];

    return (
        <>
          <SectionContainer id="rb-top">
            <InnerContainer>
              <Spacer />
              <TextImageColumn>
                <Heading>YOUR NEW WORLD OF BETTING</Heading>
                <Text>
                Welcome to RivalBet. A first of its kind. Bet directly against your mates and peers.
                They may not be mates for long...
                </Text>
              </TextImageColumn>
              <ImageContainerOne>
                <img src={HeroImage} width={420} height={500} alt="" />
              </ImageContainerOne>
              <Spacer />
            </InnerContainer>
          </SectionContainer>

          <SectionContainer isWhite>
            <ImageCarousel isRb data={carouselData}/>
          </SectionContainer>

          <SectionContainer>
            <InnerContainer>
              <Spacer />
              <TextImageColumn>
                <Heading style={{alignSelf: "start"}}>RivalBet Markets</Heading>
                <Text>
                    RivalBet is the newest sports betting app in Australia. You can bet with mates across a variety of markets.
                </Text>
                <Text>
                    We offer loads of sporting markets: AFL, NRL, NBA, NFL, UFC, EPL, Champions League, 
                    Horse Racing and many more.
                </Text>
                <Text>
                    We keep adding more markets and have special featured markets for events such as the 
                    Olympics.
                </Text>
              </TextImageColumn>
              <ImageContainerTwo>
                <img src={BottomImage} alt="" />
              </ImageContainerTwo>
              <Spacer />
            </InnerContainer>
          </SectionContainer>
        </>
    )
}

export default Rb;
