import React, { useState, useEffect } from 'react';
import { debounce } from '../Utilities/helper';
import styled, { css } from 'styled-components/macro';
import { menuData } from '../data/MenuData';
import { ColorData } from '../data/colorData';
import { FaBars } from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../images/logo.png';

const Nav = styled.nav`
height: 6rem;
display: flex;
justify-content: space-between;
padding: 0rem 0rem;
z-index: 100;
position: fixed;
width: 100%;
opactiy: 1;
`;

const NavLink = css`
    color:${ColorData.textColor};
    display: flex;
    padding: 0 2rem;
    height: 100%; 
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: ${ColorData.textHover};
        scale: 1.1;
        transition: 1s;
    }
`;

const Logo = styled(Link)`
    ${NavLink}
    color: ${ColorData.textColor};
    width: 100px;
    height 35px;
    margin-top: 30px;
    padding-left: 4rem;

    @media screen and (max-width: 768px) {
        width: 50px;
        height: 30px;
        margin-top: 15px;
        padding-left: 2rem;
    }
`;

const MenuBars = styled(FaBars)`
    display:none;

    @media screen and (max-width: 768px) {
        display: block;
        fill: white;
        height: 30px;
        width: 30px;
        transform: translate(-25%, 25%);
        margin-right:25px;
        margin-top:5px;
    }
`;

const NavMenu = styled.div`
    display: flex;
    top:0;
    align-items: flex-end;
    margin-right: -1px;
    margin-top: 35px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const NavMenuLinks = styled(Link)`
    ${NavLink}
    font-size: 20px;
`;

const NavBtn = styled(Link)`
    ${NavLink}
    font-size: 20px;
    display: flex;
    margin-right: 24px;
    top:0;
    color: ${ColorData.textColor};
    text-decoration: none;

    &:hover {
        color: ${ColorData.textHover};
        scale: 1.1;
        transition: 1s;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const Navbar = ({toggle}: any) => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;
        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);
        setPrevScrollPos(currentScrollPos);
        console.log("current:"+currentScrollPos);
        console.log("prev:"+prevScrollPos);
      }, 100);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos, visible, handleScroll]);

    let style = {
        top: visible ? '0' : '-100px',
        transition: '0.5s',
        backgroundColor: "#000",
    }
    
    return (
        <Nav style={style}>
            <Logo smooth to='/#top'><img className="logo" src={logo} alt=""/></Logo>
            <MenuBars onClick={toggle}/>            
            <NavMenu>
                {menuData.map((item) => (
                    <NavMenuLinks smooth to={item.link}>
                        {item.title}
                    </NavMenuLinks>
                ))}
                <NavBtn smooth to="/#contact">
                    Contact
                </NavBtn>
            </NavMenu>
        </Nav>
    )
}

export default Navbar;
