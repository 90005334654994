import styled, { css } from 'styled-components';
import BarlowCondensed from '../fonts/BarlowCondensed.ttf';
import BarlowMedium from '../fonts/BarlowCondensed-Medium.ttf';
import HeroImage from "../images/yb/two.webp";
import ImageCarousel from '../components/ImageCarousel';
import CarouselImage1 from "../images/yb/chat-feature.webp"
import CarouselImage2 from "../images/yb/slide2.webp"
import CarouselImage3 from "../images/yb/slide3.webp"
import CarouselImage4 from "../images/yb/slide4.webp"
import BottomImage from "../images/yb/three.webp"


const FontFace = css`
  @font-face {
    font-family: 'BarlowMedium';
    src: local('Barlow'), url(${BarlowMedium}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Barlowd';
    src: local('Barlow'), url(${BarlowCondensed}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const SectionContainer = styled.div<{isWhite?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 950px;
    background-color: ${({isWhite}) => (isWhite ? "white" : "#141414")};
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  height: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 10px
  }
`

const TextImageColumn = styled.div`
  width: 40%;
  maxWidth: 780px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @media (max-width: 800px) {
    width: 80%;
  }
`

const Spacer = styled.div`
  width: 10%;

  @media (max-width: 800px) {
    display: none;
  }
`;

const Heading = styled.h1`
  ${FontFace}
  font-family: 'BarlowMedium', sans-serif;
  font-size: 60px;
  color: white;
  text-align: left;
`;

const Text = styled.p`
  ${FontFace}
  font-family: 'Barlowd', sans-serif;
  font-size: 22px;
  color: white;
  text-align: left;
`;

const ImageContainer = styled.div`
width: 40%;
margin-left: 10px;

@media (max-width: 800px) {
  width: 80%;
  margin-left: 0px;
  margin-right: 40px;
}
`

const ImageContainerOne = styled(ImageContainer)`
  img {
    width: 520px;
    height: 640px;
  }

  @media (max-width: 800px) {
    img {
        width: 440px;
        height: 540px;
    }
  }
`

const ImageContainerTwo = styled(ImageContainer)`
  img {
    width: 520px;
    height: 500px;
  }

  @media (max-width: 800px) {
    margin-top: 60px;
    img {
        width: 420px;
        height: 400px;
    }
  }
`

const Yb = () => {    

    const carouselData = [
        {   
            image: CarouselImage1, 
            heading: "Features", 
            text: "Tons of features to choose from, change to your preferred language, find your perfect bet, or invite your friends"
        }, 
        {
            image: CarouselImage2,
            heading: "Multi's Galore",
            text: "Whether you're after your standard multi, same game multi or something in-between, we've got you covered!",
        }, 
        {
            image: CarouselImage3,
            heading: "Markets",
            text: "With some events having over 100 markets to choose from, there will always be something you're looking for!",
        }, 
        {
            image: CarouselImage4,
            heading: "Events",
            text: "Betting on a top A League team, U21 game, maybe it's Esports you're after? We have an extensive range to pick from.",
        }, 
      ]

    return (
        <>
          <SectionContainer id="yb-top">
            <InnerContainer>
              <Spacer />
              <TextImageColumn>
                <Heading>BRINGING STYLE AND FLAIR TO THE MARKET</Heading>
                <Text>
                Welcome to YamoBet. A muti-language betting experience that puts the power
                back into your hands with our wide variety of markets!
                </Text> 
              </TextImageColumn>
              <ImageContainerOne>
                <img src={HeroImage} width={420} height={500} alt="" />
              </ImageContainerOne>
              <Spacer />
            </InnerContainer>
          </SectionContainer>

          <SectionContainer isWhite>
            <ImageCarousel data={carouselData}/>
          </SectionContainer>

          <SectionContainer>
            <InnerContainer>
              <Spacer />
              <TextImageColumn>
                <Heading style={{alignSelf: "start"}}>YamoBet Markets</Heading>
                <Text>
                    RivalBet is a brand new sports betting app in the Ivory Coast, Africa. Boasting a huge variety of markets, and weekly promotions, so you're never bored!
                </Text>
                <Text>
                    We offer markets on Basketball, Soccer, Tennis, Esports, Handball, Table Tennis, and much more.
                </Text>
              </TextImageColumn>
              <ImageContainerTwo>
                <img src={BottomImage} alt="" />
              </ImageContainerTwo>
              <Spacer />
            </InnerContainer>
          </SectionContainer>
        </>
    )
}

export default Yb;
