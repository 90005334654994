import BarlowCondensed from '../fonts/BarlowCondensed.ttf';
import styled, {css} from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import 'swiper/swiper-bundle.min.css';
import {useEffect, useRef, useState} from 'react';


const FontFace = css`
  @font-face {
    font-family: 'Barlowd';
    src: local('Barlow'), url(${BarlowCondensed}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const SwiperContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;

  img {
    max-height: 600px;
    max-width: 250px;
    align-self: center;
  }
`;

const TextContainer = styled.div`
  width: 80%;
  align-self: center;
  margin-top: 10px;

  h2 {
    ${FontFace}
    font-family: 'BarlowMedium', sans-serif;
    text-align: center;
    padding-bottom: 5px;
  }

  p {
    ${FontFace}
    font-family: 'BarlowMedium', sans-serif;
    text-align: justify;
    color: #616161;
    font-size: 18px;
  }
`;

const NavButtons = styled.button<{isRb?: boolean}>`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({isRb}) => (isRb ? "#011752" : "#141414")};
  border: none;
  color: white;
  font-size: 20px;
  margin-bottom: 100px
`

type ImageCarouselProps = {
  data: any[];
  isRb?: boolean;
}

const ImageCarousel = ({data, isRb}: ImageCarouselProps) => {
  const sliderRef = useRef<any>();
  const [slidesPerView, setSlidesPerView] = useState(3);

  const resizeHandler = () => {
    if (window.innerWidth <= 1100) {
      setSlidesPerView(1);
    } else if (window.innerWidth <= 1800) {
      setSlidesPerView(2);
    } else {
      setSlidesPerView(3);
    }
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const nextSlide = () => {
    sliderRef.current?.slideNext();
    setTimeout(() => sliderRef.current?.autoplay.start(), 1500);
  };

  const prevSlide = () => {
    sliderRef.current?.slidePrev();
    setTimeout(() => sliderRef.current?.autoplay.start(), 1500);
  };


  return (
    <SwiperContainer>
      <NavButtons isRb={isRb} onClick={prevSlide}>{`<`}</NavButtons>
      <div style={{height: "auto", width: "55%"}}>
      <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={slidesPerView}
        spaceBetween={0}
        loop
        autoplay={{ delay: 3500 }}
        onSwiper={(swiper) => (sliderRef.current = swiper)}
        >
        {data.map((slide) => (
          <SwiperSlide>
            <SlideContainer>
              <img src={slide.image} alt=""/>
              <TextContainer>
                <h2>{slide.heading}</h2>
                <p>{slide.text}</p>
              </TextContainer>
            </SlideContainer>
          </SwiperSlide>
        ))}
        </Swiper>
        </div>
        <NavButtons isRb={isRb} onClick={nextSlide}>{`>`}</NavButtons>
      </SwiperContainer>
  );
};

export default ImageCarousel;