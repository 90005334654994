import styled from 'styled-components';
import {ColorData} from '../data/colorData';
import ContactImage from '../images/contact.svg';

const Section = styled.section`
    width: 100%;
    height: 100%;
    padding-bottom: 4rem;
    padding-right: 4rem;
    padding-left: 4rem;
    background-color: ${ColorData.background1};
    color: ${ColorData.textColor};
    font-size: 16px;

    @media screen and (max-width: 768px) {
        padding-top: 10rem;
    }
`;

const Container = styled.div`
    padding-top: 4rem;
    padding-bottom: 1rem;
    text-align: center;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ImageContainer = styled.div`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 45%;
    padding-top: 1rem;
    fill:#ffffff;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const ContactUs = ({myRef}: any) => {
    return (
        <Section>
            <Container id={myRef}>
                    <h1>Contact Us</h1>
                    <ImageContainer>
                        <img src={ContactImage} alt="" />
                    </ImageContainer>
            </Container>
        </Section>
    )
}

export default ContactUs