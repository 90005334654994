import styled from 'styled-components';
import {ColorData} from '../data/colorData';

const Section = styled.section`
    background: ${ColorData.background1};
    color: ${ColorData.textColor};
    width: 100%;
    padding: 1rem;
    font-size: 12px;
`;

const Container = styled.div`
    height: 100%;
    width: 100%;
    padding: 1rem;
`;

const FooterTop = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const Quote = styled.div`
    flex: 1;
    margin-left: 0;
    width: 100%;
    text-align: center;
`;

const Footer = () => {
    return (
        <Section>
            <Container>
                <FooterTop>
                    <Quote>
                        <p>Copyright &copy; {new Date().getFullYear()} Apthletic Inc - All Rights Reserved</p>
                    </Quote>
                </FooterTop>
            </Container>
        </Section>
    )
}

export default Footer
