export const ColorData = {
    mainColor: '#000000',
    background1: '#000000',
    background2: '#ffffff',
    textColor: '#ffff',
    textColor2: '#000',
    textHover: '#FA7120',
    textDrop: '#ffff',
    textDropHover: '#2b2727',
};

