import InfoSection from '../components/InfoSection';
import Hero from '../components/Hero';
import {HeroData, InfoDataOne, InfoDataTwo} from '../data/InfoData';
import ContactUs from '../components/ContactUs';
import {Projects} from '../components/Projects';
const Home = () => {
    return (
        <>
          <Hero {...HeroData} myRef = {"top"} />
          <InfoSection {...InfoDataOne} myRef = {"intro"} />
          <InfoSection {...InfoDataTwo} myRef = {"vision"} />
          <Projects myRef="projects"/>
          <ContactUs myRef = {"contact"}/>         
        </>
    )
}

export default Home
