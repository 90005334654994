import {useEffect, useState} from "react";
import styled from 'styled-components';
import {FiArrowUpCircle} from 'react-icons/fi';
import {ColorData} from '../data/colorData';

const ScrollTop = styled.div`
    height: 100%;
    top:88vh;
    left: 90vw;
    display: flex;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;

    @media screen and (max-width: 768px) {
        left: 81vw;
        }
`;

const Button = styled(FiArrowUpCircle)`
        height: 50px;
        width: 50px;
        color: ${ColorData.textHover};

        &:hover {
          color: ${ColorData.textHover};
          scale: 1.2;
          transition: 1s;
      }
`;

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  
  return (
    <ScrollTop>
      {isVisible && 
        <Button onClick={scrollToTop}>
        </Button>}
    </ScrollTop>
  );
}