import styled from "styled-components"
import RbLogo from "../images/rb_logo.png";
import YbLogo from "../images/yb_logo.png";
import { HashLink as Link } from 'react-router-hash-link';

type ProjectsProps = {
  myRef: string;
}

export const Projects = ({myRef}: ProjectsProps) => {



  const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 900px;
    width: 100%;
  `
  
  const MainTitle = styled.h1`
    height: 20%;
    text-align: center;
    font-size: 60px;
    font-weight: 900;
  `

  const InnerContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 20%;
    width: 100%;
  `

  const ProjectContainer = styled(Link)<{isRb?: boolean;}>`
    background: none;
    border: none;
    padding: 0;
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    img {
      height: 100px;
      width: 100px;
      border-radius: 50px;
      background-color: ${({isRb}) => (isRb ? "#011752" : "#141414")};
    }
  `

  const Heading = styled.h1`
  font-size: 50px;
  color: black;
`;

const Text = styled.p`
  font-size: 22px;
  color: black;
  padding-top: 15px;
  width: 60%;
`;

  return (
    <MainContainer>
      <MainTitle>Projects</MainTitle>
    <InnerContainer id={myRef}>
      <ProjectContainer isRb to="/rivalbet#rb-top">
        <Heading>RivalBet</Heading>
        <img src={RbLogo} alt="" />
        <Text>One of a kind peer to peer betting directly against your mates.</Text>
      </ProjectContainer>
      <ProjectContainer to="/yamobet#yb-top">
        <Heading>YamoBet</Heading>
        <img src={YbLogo} alt="" />
        <Text>Fixed odds, multi language betting exchange with a wide variety of markets.</Text>
      </ProjectContainer>
    </InnerContainer>
    </MainContainer>
  ) 
}