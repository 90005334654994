import React, {useState} from 'react';
import Dropdown from './components/Dropdown';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import GlobalStyle from './globalStyles';
import {Switch, Route} from 'react-router-dom';
import Home from './pages';
import ScrollToTop from './components/ScrollToTop';
import GlobalFonts from './fonts/fonts';
import Rb from './pages/Rb';
import Yb from './pages/Yb';

function App() {
  const[isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <GlobalStyle />
      <GlobalFonts />
      <Navbar toggle={toggle}/>
      <Dropdown isOpen={isOpen} toggle={toggle}/>
      <ScrollToTop />

      <Switch>
        <Route path ="/" exact component={Home} />
        <Route path='/rivalbet' component={Rb} />
        <Route path='/yamobet' component={Yb} />
        <Route path="*" exact component={Home} />
      </Switch>

      <Footer /> 
    </>
  );
}

export default App;
