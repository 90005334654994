import styled from 'styled-components';
import {ColorData} from '../data/colorData';

const Section = styled.section<{ reverse: boolean }>`
overflow: hidden;
    display: block;
    font-size: 30px;
    width: 100%;
    height: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: ${({ reverse } ) => (reverse ? ColorData.background2 : ColorData.background1)};
    color: ${({ reverse } ) => (reverse ? ColorData.textColor2 : ColorData.textColor)};
`;

const Container = styled.div`
    transform: translateX(20%);
    width: 70%;    
`;

const TextSection = styled.div`
    font-size: 30px;
    display: block;
    text-align: center;
    line-height: 1.4;
    padding: 10rem 4rem;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding: 6rem 0rem;
        width: 100%;
        font-size: 20px;
    }
`;

const ExtraLink = styled.div<{ reverse: boolean }>`
    a {
        text-decoration: none;
        color: ${({ reverse } ) => (reverse ? ColorData.textColor2 : ColorData.textColor)};
    }
`;

interface infoTypes {
    heading: string;
    paragraphOne: string; 
    paragraphTwo: string; 
    myRef: any;
    reverse: boolean;
    link: string;
}

const InfoSection = ({heading, paragraphOne, paragraphTwo, myRef, reverse, link}: infoTypes) => {    
    return (
        <Section reverse={reverse}>
            <Container id={myRef}>    

                <TextSection>
                    <h1>{heading}</h1>
                    <p>{paragraphOne}</p>
                    <p>{paragraphTwo}</p>
                    {!!link ? 
                    <ExtraLink reverse={reverse}>Checkout <a href="https://www.rivalbet.com.au/" target="https://www.rivalbet.com.au/">{link}</a> now!</ExtraLink>
                    : null}
                    </TextSection>
            </Container>
        </Section>
    )
}

export default InfoSection;
