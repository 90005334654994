import { createGlobalStyle } from 'styled-components';
import PoppinReg from './PoppinsReg.ttf';
import BarlowCondensed from "./BarlowCondensed.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Poppin';
        src: local('Poppin'), url(${PoppinReg}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Barlowd';
        src: local('Barlow'), url(${BarlowCondensed}) format('truetype');
        font-weight: normal;
        font-style: normal;
      }
`;
